@charset "utf-8";

@font-face {
    font-family: 'charterbold_italic';
    src: url('../fonts/charter_bold_italic-webfont.eot');
    src: url('../fonts/charter_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/charter_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charterbold';
    src: url('../fonts/charter_bold-webfont.eot');
    src: url('../fonts/charter_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/charter_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charteritalic';
    src: url('../fonts/charter_italic-webfont.eot');
    src: url('../fonts/charter_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/charter_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'charterregular';
    src: url('../fonts/charter_regular-webfont.eot');
    src: url('../fonts/charter_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/charter_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// Our variables
$base-font-family: charterregular, Helvetica, Arial, sans-serif;
$base-font-size:   18px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
